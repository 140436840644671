@import "/home/ec2-user/workspace/laams_Rampenfonds_vrf-app_master/eloket/src/theme/includes.scss";@import "/home/ec2-user/workspace/laams_Rampenfonds_vrf-app_master/eloket/node_modules/@skryv/core-react-vo/src/theme/includes.scss";
.vl-col--4-12 div{
    max-width: 19.2em;
    min-width: 10em;
}

  .vl-col--2-12 div{
    max-width: 9em;
  }

  @media only screen and (max-width: 1107px) {
    .vl-col--4-12 .input-component-wrapper {
        height: 50px;
    }

    .vl-col--2-12 .input-component-wrapper {
        height: 50px;
    }

  }


  @media only screen and (max-width: 776px) {
    .vl-col--4-12 {
      display:flex;
      flex-direction: column-reverse;
    }
    .vl-col--2-12 {
      display:flex;
      flex-direction: column-reverse;
    }
  }