@import "/home/ec2-user/workspace/laams_Rampenfonds_vrf-app_master/eloket/src/theme/includes.scss";@import "/home/ec2-user/workspace/laams_Rampenfonds_vrf-app_master/eloket/node_modules/@skryv/core-react-vo/src/theme/includes.scss";
.skr-fieldset {
  &.vrf-compact-fieldset {
    padding-left: 1rem;

    .editor-component {
      padding-left: 1rem;
      margin-bottom: 1rem;
   }
  }
}